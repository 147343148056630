<template>
  <section>
    <h3 class="pb-1">{{ heading }}</h3>
    <b-card no-body class="py-1">
      <!-- Case Number -->
      <div v-for="(item, index) in Object.keys(items)" :key="index">
        <b-col class="p-0">
          <hr style="margin: 5px 0" v-if="index !== 0" />
          <b-row class="px-1">
            <b-col class="col-5">
              <h6 class="m-0">
                {{
                  item
                    .split('_')
                    .join(' ')
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    )
                }}
              </h6>
            </b-col>
            <b-col class="col-7">
              <p class="m-0">
                {{ items[item] }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </div>
    </b-card>
  </section>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    data: {
      default: null
    },
    title: {
      default: 'Details'
    }
  },
  data() {
    return {
      items: this.data,
      heading: this.title
    }
  }
}
</script>
