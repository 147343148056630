<template>
    <div v-if="data.reference_case_details !== null">
      <section v-if="data.reference_case_details.length" class="pb-2">
        <h3 class="pb-1">Reference Case Details</h3>
        <b-row>
          <b-col class="col-12">
            <app-collapse :type="'border'">
              <app-collapse-item title="View Reference Case Details" class="text-info">
                <b-table
                  stacked
                  responsive="sm"
                  :items="data.reference_case_details"
                /> </app-collapse-item
            ></app-collapse>
          </b-col>
        </b-row>
      </section>
    </div>
  </template>
  
  <script>
  import { BCard, BRow, BCol, BTable } from 'bootstrap-vue'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BTable,
      AppCollapse,
      AppCollapseItem
    },
    props: {
      matter: {
        default: null
      }
    },
    data() {
      return {
        data: this.matter
      }
    }
  }
  </script>
  