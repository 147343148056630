<template>
  <section>
    <b-row>
      <b-col class="col-8">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-toggle="'matter-invoice-' + buttonType"
          :variant="buttonType === 'sm' ? 'outline-info' : 'success'"
          :class="buttonType === 'sm' ? 'btn-sm' : 'mb-0'"
          block
          @click="fetchInvoices"
        >
          {{ buttonType === 'sm' ? 'Invoices' : 'Invoices' }}
        </b-button>
      </b-col>
      <b-col class="col-4">
        <b-button
          :variant="'outline-primary'"
          :class="buttonType === 'sm' ? 'btn-sm' : 'mb-0'"
          block
          @click="
            $router.push({
              name: 'invoice-add',
              params: { id: matter._id, type: 'matter' }
            })
          "
        >
          <feather-icon
            :id="`new-invoice-icon`"
            icon="PlusIcon"
            size="16"
            class="mr-25"
          />
        </b-button>
      </b-col>
    </b-row>
    <template>
      <b-sidebar
        :id="'matter-invoice-' + buttonType"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        right
      >
        <template #header="{ hide }">
          <b-col>
            <b-row class="justify-content-between px-1 py-1">
              <h5 class="mb-0">Invoices</h5>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </b-row>
          </b-col>
        </template>
        <template #default="{ hide }">
          <b-row align-v="stretch">
            <b-col d-flex justify-center align-center>
              <!-- Body -->
              <b-overlay
                :show="$store.state.invoiceStore.isInvoiceLoading"
                rounded="sm"
                variant="transparent"
                opacity="0.5"
                blur="2px"
              >
                <!-- From -->
                <h6
                  v-if="
                    !$store.state.invoiceStore.allInvoices.length &&
                    !$store.state.invoiceStore.isInvoiceLoading
                  "
                  class="text-center mt-2"
                >
                  Invoice Not Available!
                </h6>
                <div v-else>
                  <!-- badge -->
                  <b-list-group>
                    <div
                      v-for="(item, index) in $store.state.invoiceStore
                        .allInvoices"
                      v-bind:key="item._id"
                    >
                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center cursor-pointer"
                      >
                        <b-col>
                          <b-row class="justify-content-between">
                            <h6 class="text-primary">{{ item.invoice_num }}</h6>
                            <h6>₹ {{ getSum(item.products) }}</h6>
                          </b-row>
                          <b-row
                            ><p>{{ item.subject }}</p></b-row
                          >
                          <b-row class="justify-content-between">
                            <small
                              >Created At: {{ getDate(item.created_at) }}</small
                            >
                            <b-badge
                              variant="primary"
                              pill
                              class="badge-round"
                              @click="
                                downloadInvoice({
                                  url: item.s3url,
                                  invoice_num: item.invoice_num,
                                  unique_id: item.unique_id
                                })
                              "
                            >
                              <feather-icon
                                :id="`invoice-row-${item._id}-preview-icon`"
                                icon="DownloadIcon"
                                size="16"
                                class="mr-25"
                              />
                              <span>Download</span>
                            </b-badge>
                          </b-row>
                        </b-col>
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </div>
              </b-overlay>
            </b-col>
          </b-row>
        </template>
        <template #footer>
          <div class="px-1 py-1 text-center">
            <!-- <ExportAllJudgmentButton
                :matter_id="matter._id"
                :unique_id="matter.unique_id"
              ></ExportAllJudgmentButton> -->
          </div>
        </template>
      </b-sidebar>
    </template>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BBadge,
  BRow,
  BCol,
  BListGroup,
  BAvatar,
  BListGroupItem,
  VBToggle,
  BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@core/utils/filter'
import axios from 'axios'
import moment from 'moment'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BBadge,
    BRow,
    BCol,
    BAvatar,
    BOverlay,
    BListGroup,
    BListGroupItem
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  props: {
    data: {
      default: null
    },
    type: {
      default: 'lg'
    }
  },
  data() {
    return {
      matter: this.data,
      buttonType: this.type
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('Do, MMMM YYYY')
    },
    getSum(item) {
      let sum = 0
      for (let i = 0; i < item.length; i++) {
        sum += parseFloat(item[i].price)
      }

      return sum
    },
    fetchInvoices() {
      this.$store.dispatch('invoiceStore/fetchMatterInvoice', this.matter._id)
    },

    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    downloadInvoice({ url, invoice_num, unique_id }) {
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      if(unique_id){
        this.downloadAs(href, `${unique_id}-${invoice_num}.pdf`)
      }else{
        this.downloadAs(href, `${invoice_num}.pdf`)
      }
    }
  },
  setup() {
    return {
      formatDate
    }
  }
}
</script>
