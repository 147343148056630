<template>
  <b-overlay
    :show="$store.state.matterStore.exportJudgmentLoading"
    rounded="sm"
    variant="transparent"
    opacity="0.5"
    blur="2px"
  >
    <div v-if="$store.state.matterStore.judgmentList.length > 0">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="danger"
        v-b-tooltip.hover
        title="Export PDF"
        :disabled="!id"
        class="btn-sm"
        @click="exportAllJudgment"
      >
        Export All
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import axios from 'axios'
import { BButton, VBTooltip, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BOverlay
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  props: {
    matter_id: {
      type: String,
      default: null
    },
    unique_id: {
      type: String,
      default: 'matter'
    }
  },
  data() {
    return {
      id: this.matter_id,
      uId: this.unique_id
    }
  },
  methods: {
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    exportAllJudgment() {
      this.$store
        .dispatch('matterStore/exportAllJudgments', { id: this.id })
        .then((response) => {
          console.log('Judgments Export --> ', response)
          if (response) {
            let href = response.url.replace(
              'http://s3.mymunshi.live',
              'https://app.mymunshi.in/exports'
            )
            let uu = this.uId.includes('-')
              ? this.uId.split('-').slice(2).join('/')
              : this.uId
            this.downloadAs(href, `${uu}-all-orders.pdf`)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
