var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('basic-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'case_details'
      )
    )?_c('div',[_c('case-status',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter,"detail":_vm.$store.state.matterStore.currentmatter.case_details}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes('category')
    )?_c('div',[_c('category-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'linked_matters'
      )
    )?_c('div',[(_vm.$store.state.matterStore.currentmatter.linked_matters)?_c('linked-matters',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}):_vm._e()],1):_vm._e(),_c('div',[_c('petitioner-respondents',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'listing_history'
      )
    )?_c('div',[_c('listing-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),_c('b-card',{attrs:{"no-body":""}},[_c('app-collapse',{attrs:{"accordion":""}},[(Object.keys(_vm.$store.state.matterStore.currentmatter).includes('notices'))?_c('app-collapse-item',{attrs:{"title":"Notices"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.notices,"title":""}})],1):_vm._e(),(Object.keys(_vm.$store.state.matterStore.currentmatter).includes('defects'))?_c('app-collapse-item',{attrs:{"title":"Defects"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.defects,"title":""}})],1):_vm._e(),(Object.keys(_vm.$store.state.matterStore.currentmatter).includes('earlier_court_details'))?_c('app-collapse-item',{attrs:{"title":"Earlier Court Details"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.earlier_court_details,"title":""}})],1):_vm._e(),(Object.keys(_vm.$store.state.matterStore.currentmatter).includes('ia'))?_c('app-collapse-item',{attrs:{"title":"IA Details"}},[_c('CollapseListItem',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.ia}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }