var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.title === 'Final Orders')?_c('div',[(_vm.data.final_orders !== null)?_c('div',[(Object.keys(_vm.data.final_orders).length)?_c('section',[_c('h3',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.heading))]),_c('b-card',{attrs:{"no-body":""}},[_c('b-list-group',_vm._l((_vm.data.final_orders),function(item,index){return _c('div',{key:item._id},[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center cursor-pointer",attrs:{"variant":"success"},on:{"click":function($event){return _vm.exportJudgment({
                  url: item.url,
                  date: item.order_at,
                  matter_id: _vm.data._id
                })}}},[_c('b-row',{staticClass:"align-items-center"},[_c('b-avatar',{staticClass:"ml-1",attrs:{"variant":"secondary","size":"2rem"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.order_at))])],1),_c('b-badge',{staticClass:"badge-round",attrs:{"variant":"primary","pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"id":("invoice-row-" + (item._id) + "-preview-icon"),"icon":"Link2Icon","size":"16"}}),_c('span',[_vm._v("Preview")])],1)],1)],1)}),0)],1)],1):_vm._e()]):_vm._e()]):_c('div',[(_vm.data.orders !== null)?_c('div',[(Object.keys(_vm.data.orders).length)?_c('section',[_c('h3',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.heading))]),_c('b-card',{attrs:{"no-body":""}},[_c('b-list-group',_vm._l((_vm.data.orders),function(item,index){return _c('div',{key:item._id},[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center cursor-pointer",on:{"click":function($event){return _vm.exportJudgment({
                  url: item.url,
                  date: item.order_at,
                  matter_id: _vm.data._id
                })}}},[_c('b-row',{staticClass:"align-items-center"},[_c('b-avatar',{staticClass:"ml-1",attrs:{"variant":"secondary","size":"2rem"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.order_at))])],1),_c('b-badge',{staticClass:"badge-round",attrs:{"variant":"primary","pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"id":("invoice-row-" + (item._id) + "-preview-icon"),"icon":"Link2Icon","size":"16"}}),_c('span',[_vm._v("Preview")])],1)],1)],1)}),0)],1)],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }