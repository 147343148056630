<template>
  <div v-if="data.acts != null">
    <section v-if="data.acts.length !== 0">
      <h3 class="pb-1">Acts</h3>
      <b-card no-body>
        <template #header>
          <b-col>
            <b-row class="justify-content-between">
              <b-col class="col-7">
                <p class="m-0">Act</p>
              </b-col>
              <b-col class="col-5">
                <b-row class="justify-content-start">
                  <p class="m-0">Section</p>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </template>
        <hr class="m-0 p-0" />
        <!-- First Hearing Date -->
        <b-row class="p-1" v-for="item in data.acts" v-bind:key="item">
          <b-col cols="7">
            <p class="m-0" v-if="Object.keys(item).includes('under_acts')">
              {{ item.under_acts }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
          <b-col cols="5">
            <p class="m-0" v-if="Object.keys(item).includes('under_section')">
              {{ item.under_section }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  }
}
</script>
