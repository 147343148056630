var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-toggle",rawName:"v-b-toggle",value:('matter-notes-docs-' + _vm.buttonType),expression:"'matter-notes-docs-' + buttonType"}],class:_vm.buttonType === 'sm' ? 'btn-sm' : 'mb-75',attrs:{"variant":_vm.buttonType === 'sm' ? 'outline-info' : 'info',"block":""},on:{"click":_vm.fetchNotesDocs}},[_vm._v(" "+_vm._s(_vm.buttonType === 'sm' ? 'Note/Doc' : 'Notes & Docs')+" ")]),[_c('b-sidebar',{attrs:{"id":'matter-notes-docs-' + _vm.buttonType,"sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","right":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var hide = ref.hide;
return [_c('b-col',[_c('b-row',{staticClass:"justify-content-between p-75"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Notes/Documents")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('hr')],1)]}},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-tabs',{attrs:{"fill":""}},[_c('b-tab',{attrs:{"title":"Notes"},on:{"click":function($event){return _vm.onTabChange(0)}}},[_c('b-overlay',{attrs:{"show":_vm.$store.state.matterStore.notesLoading,"rounded":"sm","variant":"transparent","opacity":"0.5","blur":"2px"}},[_c('vue-perfect-scrollbar',{ref:_vm.refChatLogPS,staticClass:"user-chats scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},[(_vm.$store.state.matterStore.notesList.length)?_c('div',_vm._l((_vm.$store.state.matterStore
                      .notesList),function(item,index){return _c('div',{key:item._id},[_c('b-card',{staticClass:"mb-1 mx-1"},[_c('b-card-text',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('b-row',{staticClass:"justify-content-between px-1"},[_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at, { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })))]),_c('b-link',{staticClass:"card-link text-danger",on:{"click":function($event){return _vm.deleteNoteAlert(item._id)}}},[_vm._v(" Delete ")])],1)],1)],1)}),0):_vm._e(),(
                    !_vm.$store.state.matterStore.notesList.length &&
                    !_vm.$store.state.matterStore.notesLoading
                  )?_c('h6',{staticClass:"text-center mt-2"},[_vm._v(" Notes Not Available! ")]):_vm._e()])],1)],1),_c('b-tab',{attrs:{"title":"Documents"},on:{"click":function($event){return _vm.onTabChange(1)}}},[_c('b-overlay',{attrs:{"show":_vm.$store.state.matterStore.docsLoading,"rounded":"sm","variant":"transparent","opacity":"0.5","blur":"2px"}},[_c('vue-perfect-scrollbar',{ref:_vm.refChatLogPS,staticClass:"user-chats scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},[(_vm.$store.state.matterStore.docsList.length)?_c('div',_vm._l((_vm.$store.state.matterStore.docsList),function(item,index){return _c('div',{key:item._id},[_c('b-card',{staticClass:"mb-1 mx-1"},[_c('b-card-text',[_c('b-img',{staticClass:"mr-50",attrs:{"src":require("@/assets/images/icons/file-icons/file.png"),"width":"32px"}}),_vm._v(" "+_vm._s(item.url.substring(item.url.lastIndexOf('/') + 1))+" ")],1),_c('b-row',{staticClass:"justify-content-between px-1"},[_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at, { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })))]),_c('b-link',{staticClass:"card-link text-primary",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" Open ")]),_c('b-link',{staticClass:"card-link text-danger",on:{"click":function($event){return _vm.deleteDocuemntAlert(item._id)}}},[_vm._v(" Delete ")])],1)],1)],1)}),0):_vm._e(),(
                    !_vm.$store.state.matterStore.docsList.length &&
                    !_vm.$store.state.matterStore.docsLoading
                  )?_c('h6',{staticClass:"text-center mt-2"},[_vm._v(" Document Not Available! ")]):_vm._e()])],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"px-1 py-1"},[(_vm.currentTab === 0)?_c('b-form',{staticClass:"chat-app-form",on:{"submit":function($event){$event.preventDefault();return _vm.saveNote($event)}}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-textarea',{attrs:{"id":"textarea-small","size":"sm","placeholder":"Enter your note"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary","type":"submit","disabled":!_vm.text}},[_vm._v(" Submit ")])],1)],1)],1):_vm._e(),(_vm.currentTab === 1)?_c('b-form',{staticClass:"chat-app-form",on:{"submit":function($event){$event.preventDefault();return _vm.saveDocument($event)}}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-file',{ref:"file",staticClass:"mr-1",attrs:{"id":"file-large","size":"lg","type":"file","state":Boolean(_vm.file),"placeholder":"Select Document","accept":".jpg, .png, .jpeg, .pdf, .xls, .docx"},on:{"change":_vm.doSomething},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('b-button',{attrs:{"variant":"outline-primary","type":"submit"}},[_vm._v(" Submit ")])],1)],1):_vm._e()],1)]},proxy:true}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }