<template>
    <div>
      <!-- Basic Details -->
      <basic-details
        :matter="$store.state.matterStore.currentmatter"
      ></basic-details>
      <!-- Case Status -->
      <div
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'case_details'
          )
        "
      >
        <case-status
          :matter="$store.state.matterStore.currentmatter"
          :detail="$store.state.matterStore.currentmatter.case_details"
        ></case-status>
      </div>
      <!-- Category -->
      <div
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes('category')
        "
      >
        <category-details
          :matter="$store.state.matterStore.currentmatter"
        ></category-details>
      </div>
      <!-- Linked Matters -->
      <div
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'linked_matters'
          )
        "
      >
        <linked-matters
          v-if="$store.state.matterStore.currentmatter.linked_matters"
          :matter="$store.state.matterStore.currentmatter"
        ></linked-matters>
      </div>
      <!-- Peitioner & Respondent -->
      <div
      >
        <petitioner-respondents
          :matter="$store.state.matterStore.currentmatter"
        ></petitioner-respondents>
      </div>
  
      <!-- Listening History -->
      <div
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'listing_history'
          )
        "
      >
        <listing-details
          :matter="$store.state.matterStore.currentmatter"
        ></listing-details>
      </div>

    <b-card no-body>
        <app-collapse accordion>
        
        <app-collapse-item title="Notices" v-if="Object.keys($store.state.matterStore.currentmatter).includes('notices')">
            <DynamicListTable
            :data="$store.state.matterStore.currentmatter.notices"
            title=""
            ></DynamicListTable>
        </app-collapse-item>
        <app-collapse-item title="Defects" v-if="Object.keys($store.state.matterStore.currentmatter).includes('defects')">
            <DynamicListTable
            :data="$store.state.matterStore.currentmatter.defects"
            title=""
            ></DynamicListTable>
        </app-collapse-item>
        <app-collapse-item title="Earlier Court Details" v-if="Object.keys($store.state.matterStore.currentmatter).includes('earlier_court_details')">
            <DynamicListTable
            :data="$store.state.matterStore.currentmatter.earlier_court_details"
            title=""
            ></DynamicListTable>
        </app-collapse-item>
        <app-collapse-item title="IA Details" v-if="Object.keys($store.state.matterStore.currentmatter).includes('ia')">
            <CollapseListItem
            :data="$store.state.matterStore.currentmatter.ia"
            ></CollapseListItem>
        </app-collapse-item>
    </app-collapse>
    </b-card>

      <!-- IA Details -->
      <!-- <div
        v-if="Object.keys($store.state.matterStore.currentmatter).includes('ia')"
      >
        <DynamicListTable
          :data="$store.state.matterStore.currentmatter.ia"
          title="IA Details"
        ></DynamicListTable>
      </div> -->
    </div>
  </template>
  
  <script>
  import { BRow, BCol, BCard } from 'bootstrap-vue'
  // Section Imports
  import BasicDetails from './components/basic-details/BasicDetails.vue'
  import CaseStatus from './components/status/CaseStatus.vue'
  import ListingDetails from './components/listing-history/ListingDetails.vue'
  import PetitionerRespondents from './components/petitioner-respondent/PetitionerRespondents.vue'
  import DynamicListTable from './components/DynamicListTable.vue'
  import CategoryDetails from './components/CategoryDetails.vue'
  import LinkedMatters from './components/LinkedMatters.vue'
  import LowerCourt from './components/LowerCourt.vue'
  import CrimeDetails from './components/CrimeDetails.vue'
  import BasicTable from './components/tables/BasicTable.vue'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CollapseListItem from './components/CollapseListItem.vue'
  
  export default {
    components: {
      BRow,
      BCard,
      BCol,
      // Basic Details
      BasicDetails,
      CaseStatus,
      ListingDetails,
      PetitionerRespondents,
      DynamicListTable,
      CategoryDetails,
      LinkedMatters,
      LowerCourt,
      CrimeDetails,
      BasicTable,
      AppCollapse,
      AppCollapseItem,
      CollapseListItem
    }
  }
  </script>
  