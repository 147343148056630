<template>
  <div v-if="title === 'Final Orders'">
    <div v-if="data.final_orders !== null">
      <section v-if="Object.keys(data.final_orders).length">
        <h3 class="pb-1">{{ heading }}</h3>
        <b-card no-body>
          <b-list-group>
            <div v-for="(item, index) in data.final_orders" v-bind:key="item._id">
              <b-list-group-item
                @click="
                  exportJudgment({
                    url: item.url,
                    date: item.order_at,
                    matter_id: data._id
                  })
                "
                variant="success"
                class="d-flex justify-content-between align-items-center cursor-pointer"
              >
                <b-row class="align-items-center">
                  <b-avatar variant="secondary" class="ml-1" size="2rem">
                    <feather-icon icon="FileTextIcon" />
                  </b-avatar>
                  <span class="ml-1">{{ item.order_at }}</span>
                </b-row>
                <b-badge variant="primary" pill class="badge-round">
                  <feather-icon
                    :id="`invoice-row-${item._id}-preview-icon`"
                    icon="Link2Icon"
                    size="16"
                    class="mr-25"
                  />
                  <span>Preview</span>
                </b-badge>
              </b-list-group-item>
            </div>
          </b-list-group>
        </b-card>
      </section>
    </div>
  </div>
  <div v-else>
    <div v-if="data.orders !== null">
      <section v-if="Object.keys(data.orders).length">
        <h3 class="pb-1">{{ heading }}</h3>
        <b-card no-body>
          <b-list-group>
            <div v-for="(item, index) in data.orders" v-bind:key="item._id">
              <b-list-group-item
                @click="
                  exportJudgment({
                    url: item.url,
                    date: item.order_at,
                    matter_id: data._id
                  })
                "
                class="d-flex justify-content-between align-items-center cursor-pointer"
              >
                <b-row class="align-items-center">
                  <b-avatar variant="secondary" class="ml-1" size="2rem">
                    <feather-icon icon="FileTextIcon" />
                  </b-avatar>
                  <span class="ml-1">{{ item.order_at }}</span>
                </b-row>
                <b-badge variant="primary" pill class="badge-round">
                  <feather-icon
                    :id="`invoice-row-${item._id}-preview-icon`"
                    icon="Link2Icon"
                    size="16"
                    class="mr-25"
                  />
                  <span>Preview</span>
                </b-badge>
              </b-list-group-item>
            </div>
          </b-list-group>
        </b-card>
      </section>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BBadge,
  BAvatar
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BAvatar
  },
  props: {
    matter: {
      default: null
    },
    title: {
      default: 'Orders'
    }
  },
  data() {
    return {
      data: this.matter,
      heading: this.title
    }
  },
  methods: {
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    exportJudgment({ url, date, matter_id }) {
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      let dd = date.toString().split('T')[0]
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `${uu}-order-${dd}.pdf`)
    }
  }
}
</script>
