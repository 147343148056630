<template>
  <div>
    <!-- Basic Details -->
    <basic-details
      :matter="$store.state.matterStore.currentmatter"
    ></basic-details>
    <!-- Case Status -->
    <tribunals-status
      :matter="$store.state.matterStore.currentmatter"
    ></tribunals-status>

    <!-- Complainant Against Detail -->
    <div
      class="mb-1"
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'rc_trc_case_details'
        ) && $store.state.matterStore.currentmatter.rc_trc_case_details !== null
      "
    >
      <DynamicObjectDetail
        :data="$store.state.matterStore.currentmatter.rc_trc_case_details"
        :title="'RC/TRC Case Details'"
      ></DynamicObjectDetail>
    </div>
    <!-- Linked Matters -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'linked_matters'
        )
      "
    >
      <linked-matters
        v-if="$store.state.matterStore.currentmatter.linked_matters"
        :matter="$store.state.matterStore.currentmatter"
      ></linked-matters>
    </div>
    <!-- Peitioner & Respondent -->
    <div
    >
      <petitioner-respondents
        :matter="$store.state.matterStore.currentmatter"
      ></petitioner-respondents>
    </div>
    <!-- Reference Case Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'reference_case_details'
        )
      "
    >
      <reference-case-details
        :matter="$store.state.matterStore.currentmatter"
      ></reference-case-details>
    </div>
    <!-- Document Filing Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'document_details'
        )
      "
    >
      <document-filing-details
        :matter="$store.state.matterStore.currentmatter"
      ></document-filing-details>
    </div>
    <!-- Listening History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'case_proceeding'
        )
      "
    >
      <case-proceeding
        :matter="$store.state.matterStore.currentmatter"
      ></case-proceeding>
    </div>

    <!-- Listening History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'listing_history'
        )
      "
    >
      <listing-details
        :matter="$store.state.matterStore.currentmatter"
      ></listing-details>
    </div>

    <!-- Orders -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'tabular_orders'
        )
      "
    >
      <tabular-orders
        :matter="$store.state.matterStore.currentmatter"
      ></tabular-orders>
    </div>
    <!-- Complainant Against Detail -->
    <div
      class="mb-1"
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'complainant_against_detail'
        ) &&
        $store.state.matterStore.currentmatter.complainant_against_detail !==
          null
      "
    >
      <DynamicObjectDetail
        :data="
          $store.state.matterStore.currentmatter.complainant_against_detail
        "
        :title="'Complainant Against Detail'"
      ></DynamicObjectDetail>
    </div>
    <b-row>
      <!-- Complainant Detail -->
      <b-col
        class="col-12 col-md-6 mb-1"
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'complainant_detail'
          ) &&
          $store.state.matterStore.currentmatter.complainant_detail !== null
        "
      >
        <DynamicObjectDetail
          :data="$store.state.matterStore.currentmatter.complainant_detail"
          :title="'Complainant Detail'"
        ></DynamicObjectDetail>
      </b-col>
      <!-- Complainant Detail -->
      <b-col
        class="col-12 col-md-6 mb-1"
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'complaint_detail'
          ) && $store.state.matterStore.currentmatter.complaint_detail !== null
        "
      >
        <DynamicObjectDetail
          :data="$store.state.matterStore.currentmatter.complaint_detail"
          :title="'Complaint Details'"
        ></DynamicObjectDetail>
      </b-col>
    </b-row>
    <!-- complaints_ground -->
    <div
      class="mb-1"
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'complaints_ground'
        )
      "
    >
      <DynamicListTable
        :data="$store.state.matterStore.currentmatter.complaints_ground"
        title="Complaints Ground"
      ></DynamicListTable>
    </div>

    <div
      class="mb-1"
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'payment_made_to_promoter'
        )
      "
    >
      <DynamicListTable
        :data="$store.state.matterStore.currentmatter.payment_made_to_promoter"
        title="Payment Made to Promoter"
      ></DynamicListTable>
    </div>

    <!-- RC/TRC Listing History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'rc_trc_listing_history'
        )
      "
    >
      <DynamicListTable
        :data="$store.state.matterStore.currentmatter.rc_trc_listing_history"
        title="RC/TRC Listing History"
      ></DynamicListTable>
    </div>

    <!-- IA Details -->
    <div
      v-if="Object.keys($store.state.matterStore.currentmatter).includes('ia')"
    >
      <DynamicListTable
        :data="$store.state.matterStore.currentmatter.ia"
        title="IA Details"
      ></DynamicListTable>
    </div>

    <!-- Inferior Court wise details -->
    <h3 v-if="
            Object.keys($store.state.matterStore.currentmatter).includes(
              'inferior_court_wise_details_of_cases_divisional_level'
            ) || Object.keys($store.state.matterStore.currentmatter).includes(
              'inferior_court_wise_details_of_cases_tehsil_level'
            ) || Object.keys($store.state.matterStore.currentmatter).includes(
              'inferior_court_wise_details_of_cases_district_level'
            )
          " class="pb-1">Inferior Court wise details</h3>
    <b-card no-body>
      <app-collapse accordion>
        <app-collapse-item
          title="Divisional Level"
          v-if="
            Object.keys($store.state.matterStore.currentmatter).includes(
              'inferior_court_wise_details_of_cases_divisional_level'
            )
          "
        >
          <DynamicListTable
            :data="
              $store.state.matterStore.currentmatter
                .inferior_court_wise_details_of_cases_divisional_level
            "
            title=""
          ></DynamicListTable>
        </app-collapse-item>
        <app-collapse-item
          title="District Level"
          v-if="
            Object.keys($store.state.matterStore.currentmatter).includes(
              'inferior_court_wise_details_of_cases_tehsil_level'
            )
          "
        >
          <DynamicListTable
            :data="
              $store.state.matterStore.currentmatter
                .inferior_court_wise_details_of_cases_tehsil_level
            "
            title=""
          ></DynamicListTable>
        </app-collapse-item>
        <app-collapse-item
          title="Tahsil Level"
          v-if="
            Object.keys($store.state.matterStore.currentmatter).includes(
              'inferior_court_wise_details_of_cases_district_level'
            )
          "
        >
          <DynamicListTable
            :data="
              $store.state.matterStore.currentmatter
                .inferior_court_wise_details_of_cases_district_level
            "
            title=""
          ></DynamicListTable>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <!-- Other claims registered on the plot/gate in question -->
    <h3 v-if="
            Object.keys($store.state.matterStore.currentmatter).includes(
              'details_of_other_claims_registered_on_the_plot_divisional_level'
            ) || Object.keys($store.state.matterStore.currentmatter).includes(
              'details_of_other_claims_registered_on_the_plot_district_level'
            ) || Object.keys($store.state.matterStore.currentmatter).includes(
              'details_of_other_claims_registered_on_the_plot_tehsil_level'
            )
          " class="pb-1">Other claims registered on the plot/gate in question</h3>
    <b-card no-body>
      <app-collapse accordion>
        <app-collapse-item
          title="Divisional Level"
          v-if="
            Object.keys($store.state.matterStore.currentmatter).includes(
              'details_of_other_claims_registered_on_the_plot_divisional_level'
            )
          "
        >
          <DynamicListTable
            :data="
              $store.state.matterStore.currentmatter
                .details_of_other_claims_registered_on_the_plot_divisional_level
            "
            title=""
          ></DynamicListTable>
        </app-collapse-item>
        <app-collapse-item
          title="District Level"
          v-if="
            Object.keys($store.state.matterStore.currentmatter).includes(
              'details_of_other_claims_registered_on_the_plot_district_level'
            )
          "
        >
          <DynamicListTable
            :data="
              $store.state.matterStore.currentmatter
                .details_of_other_claims_registered_on_the_plot_district_level
            "
            title=""
          ></DynamicListTable>
        </app-collapse-item>
        <app-collapse-item
          title="Tahsil Level"
          v-if="
            Object.keys($store.state.matterStore.currentmatter).includes(
              'details_of_other_claims_registered_on_the_plot_tehsil_level'
            )
          "
        >
          <DynamicListTable
            :data="
              $store.state.matterStore.currentmatter
                .details_of_other_claims_registered_on_the_plot_tehsil_level
            "
            title=""
          ></DynamicListTable>
        </app-collapse-item>
      </app-collapse>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
// Section Imports
import BasicDetails from './components/basic-details/BasicDetails.vue'
import TribunalsStatus from './components/status/TribunalsStatus.vue'
import DocumentFilingDetails from './components/DocumentFilingDetails.vue'
import CaseProceeding from './components/CaseProceeding.vue'
import PetitionerRespondents from './components/petitioner-respondent/PetitionerRespondents.vue'
import ReferenceCaseDetails from './components/ReferenceCaseDetails.vue'
import LinkedMatters from './components/LinkedMatters.vue'
import TribunalOrders from './components/orders/TribunalOrders.vue'
import ListingDetails from './components/listing-history/ListingDetails.vue'
import TabularOrders from './components/listing-history/TabularOrders.vue'
import DynamicObjectDetail from './components/DynamicObjectDetail.vue'
import DynamicListTable from './components/DynamicListTable.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CollapseListItem from './components/CollapseListItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // Basic Details
    BasicDetails,
    DocumentFilingDetails,
    TribunalsStatus,
    CaseProceeding,
    PetitionerRespondents,
    ReferenceCaseDetails,
    LinkedMatters,
    TribunalOrders,
    ListingDetails,
    TabularOrders,
    DynamicObjectDetail,
    DynamicListTable,
    AppCollapse,
    AppCollapseItem,
    CollapseListItem
  }
}
</script>
