var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('basic-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'case_details'
      )
    )?_c('div',[_c('case-status',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter,"detail":_vm.$store.state.matterStore.currentmatter.case_details}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes('category')
    )?_c('div',[_c('category-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'linked_matters'
      )
    )?_c('div',[(_vm.$store.state.matterStore.currentmatter.linked_matters)?_c('linked-matters',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}):_vm._e()],1):_vm._e(),_c('div',[_c('petitioner-respondents',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'copy_petition_details'
      )
    )?_c('div',[_c('basic-table',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.copy_petition_details,"title":'Copy Petition Details'}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'lower_court_detail'
      )
    )?_c('div',[_c('basic-table',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.lower_court_detail,"title":'Lower Court Details'}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'fir_details'
      )
    )?_c('div',[_c('basic-table',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.fir_details,"title":'FIR Details'}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'impung_order_detail'
      )
    )?_c('div',[_c('basic-table',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.impung_order_detail,"title":'Impung Order Details'}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'complaint_details'
      )
    )?_c('div',[_c('basic-table',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.complaint_details,"title":'Complaint Details'}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'lac_detail'
      )
    )?_c('div',[_c('basic-table',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.lac_detail,"title":'Lac Details'}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'listing_history'
      )
    )?_c('div',[_c('listing-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),_c('b-row',[(
        Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
          'crime_dic'
        )
      )?_c('b-col',{staticClass:"col-12 col-md-6 mb-1"},[_c('crime-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
        Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
          'lower_court'
        )
      )?_c('b-col',{staticClass:"col-12 col-md-6 mb-1"},[_c('lower-court',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e()],1),(Object.keys(_vm.$store.state.matterStore.currentmatter).includes('ia'))?_c('div',[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.ia,"title":"IA Details"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }