var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('basic-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),_c('tribunals-status',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'rc_trc_case_details'
      ) && _vm.$store.state.matterStore.currentmatter.rc_trc_case_details !== null
    )?_c('div',{staticClass:"mb-1"},[_c('DynamicObjectDetail',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.rc_trc_case_details,"title":'RC/TRC Case Details'}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'linked_matters'
      )
    )?_c('div',[(_vm.$store.state.matterStore.currentmatter.linked_matters)?_c('linked-matters',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}):_vm._e()],1):_vm._e(),_c('div',[_c('petitioner-respondents',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'reference_case_details'
      )
    )?_c('div',[_c('reference-case-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'document_details'
      )
    )?_c('div',[_c('document-filing-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'case_proceeding'
      )
    )?_c('div',[_c('case-proceeding',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'listing_history'
      )
    )?_c('div',[_c('listing-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'tabular_orders'
      )
    )?_c('div',[_c('tabular-orders',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'complainant_against_detail'
      ) &&
      _vm.$store.state.matterStore.currentmatter.complainant_against_detail !==
        null
    )?_c('div',{staticClass:"mb-1"},[_c('DynamicObjectDetail',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.complainant_against_detail,"title":'Complainant Against Detail'}})],1):_vm._e(),_c('b-row',[(
        Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
          'complainant_detail'
        ) &&
        _vm.$store.state.matterStore.currentmatter.complainant_detail !== null
      )?_c('b-col',{staticClass:"col-12 col-md-6 mb-1"},[_c('DynamicObjectDetail',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.complainant_detail,"title":'Complainant Detail'}})],1):_vm._e(),(
        Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
          'complaint_detail'
        ) && _vm.$store.state.matterStore.currentmatter.complaint_detail !== null
      )?_c('b-col',{staticClass:"col-12 col-md-6 mb-1"},[_c('DynamicObjectDetail',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.complaint_detail,"title":'Complaint Details'}})],1):_vm._e()],1),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'complaints_ground'
      )
    )?_c('div',{staticClass:"mb-1"},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.complaints_ground,"title":"Complaints Ground"}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'payment_made_to_promoter'
      )
    )?_c('div',{staticClass:"mb-1"},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.payment_made_to_promoter,"title":"Payment Made to Promoter"}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'rc_trc_listing_history'
      )
    )?_c('div',[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.rc_trc_listing_history,"title":"RC/TRC Listing History"}})],1):_vm._e(),(Object.keys(_vm.$store.state.matterStore.currentmatter).includes('ia'))?_c('div',[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.ia,"title":"IA Details"}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'inferior_court_wise_details_of_cases_divisional_level'
          ) || Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'inferior_court_wise_details_of_cases_tehsil_level'
          ) || Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'inferior_court_wise_details_of_cases_district_level'
          )
        )?_c('h3',{staticClass:"pb-1"},[_vm._v("Inferior Court wise details")]):_vm._e(),_c('b-card',{attrs:{"no-body":""}},[_c('app-collapse',{attrs:{"accordion":""}},[(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'inferior_court_wise_details_of_cases_divisional_level'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Divisional Level"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter
              .inferior_court_wise_details_of_cases_divisional_level,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'inferior_court_wise_details_of_cases_tehsil_level'
          )
        )?_c('app-collapse-item',{attrs:{"title":"District Level"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter
              .inferior_court_wise_details_of_cases_tehsil_level,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'inferior_court_wise_details_of_cases_district_level'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Tahsil Level"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter
              .inferior_court_wise_details_of_cases_district_level,"title":""}})],1):_vm._e()],1)],1),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'details_of_other_claims_registered_on_the_plot_divisional_level'
          ) || Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'details_of_other_claims_registered_on_the_plot_district_level'
          ) || Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'details_of_other_claims_registered_on_the_plot_tehsil_level'
          )
        )?_c('h3',{staticClass:"pb-1"},[_vm._v("Other claims registered on the plot/gate in question")]):_vm._e(),_c('b-card',{attrs:{"no-body":""}},[_c('app-collapse',{attrs:{"accordion":""}},[(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'details_of_other_claims_registered_on_the_plot_divisional_level'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Divisional Level"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter
              .details_of_other_claims_registered_on_the_plot_divisional_level,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'details_of_other_claims_registered_on_the_plot_district_level'
          )
        )?_c('app-collapse-item',{attrs:{"title":"District Level"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter
              .details_of_other_claims_registered_on_the_plot_district_level,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'details_of_other_claims_registered_on_the_plot_tehsil_level'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Tahsil Level"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter
              .details_of_other_claims_registered_on_the_plot_tehsil_level,"title":""}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }