<template>
  <b-overlay
    :show="$store.state.matterStore.refreshJudgmentLoading"
    rounded="sm"
    variant="transparent"
    opacity="0.5"
    blur="2px"
  >
    <div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        v-b-tooltip.hover
        title="Refresh"
        :disabled="!id"
        class="btn-sm"
        @click="refreshJudgment"
      >
        Refresh
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import axios from 'axios'
import { BButton, VBTooltip, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BOverlay
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  props: {
    matter_id: {
      type: String,
      default: null
    },
    unique_id: {
      type: String,
      default: 'matter'
    }
  },
  data() {
    return {
      id: this.matter_id,
      uId: this.unique_id
    }
  },
  methods: {
    fetchJudgment() {
      this.$store.dispatch('matterStore/fetchMatterJudgment', this.id)
    },
    refreshJudgment() {
      this.$store
        .dispatch('matterStore/refreshJudgments', { matterId: this.id })
        .then((response) => {
          console.log('Judgments Export --> ', response)
          if (response.success) {
            this.fetchJudgment()
          } else {
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
