<template>
  <section>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-toggle="'matter-notes-docs-' + buttonType"
      :variant="buttonType === 'sm' ? 'outline-info' : 'info'"
      :class="buttonType === 'sm' ? 'btn-sm' : 'mb-75'"
      block
      @click="fetchNotesDocs"
    >
      {{ buttonType === 'sm' ? 'Note/Doc' : 'Notes & Docs' }}
    </b-button>
    <template>
      <b-sidebar
        :id="'matter-notes-docs-' + buttonType"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        right
      >
        <template #header="{ hide }">
          <b-col>
            <b-row class="justify-content-between p-75">
              <h5 class="mb-0">Notes/Documents</h5>

              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </b-row>
            <hr />
          </b-col>
        </template>
        <template #default="{ hide }">
          <!-- Body -->
          <b-tabs fill>
            <!-- Notes Tab -->
            <b-tab @click="onTabChange(0)" title="Notes">
              <!-- Notes Chat Area -->
              <b-overlay
                :show="$store.state.matterStore.notesLoading"
                rounded="sm"
                variant="transparent"
                opacity="0.5"
                blur="2px"
              >
                <vue-perfect-scrollbar
                  :ref="refChatLogPS"
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                >
                  <div v-if="$store.state.matterStore.notesList.length">
                    <!-- badge -->
                    <div
                      v-for="(item, index) in $store.state.matterStore
                        .notesList"
                      v-bind:key="item._id"
                    >
                      <b-card class="mb-1 mx-1">
                        <b-card-text>
                          {{ item.text }}
                        </b-card-text>
                        <b-row class="justify-content-between px-1">
                          <span>{{
                            formatDate(item.created_at, {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric'
                            })
                          }}</span>
                          <b-link
                            class="card-link text-danger"
                            @click="deleteNoteAlert(item._id)"
                          >
                            Delete
                          </b-link>
                        </b-row>
                      </b-card>
                    </div>
                  </div>
                  <h6
                    v-if="
                      !$store.state.matterStore.notesList.length &&
                      !$store.state.matterStore.notesLoading
                    "
                    class="text-center mt-2"
                  >
                    Notes Not Available!
                  </h6>
                </vue-perfect-scrollbar></b-overlay
              >
            </b-tab>
            <!-- Document Tab -->
            <b-tab @click="onTabChange(1)" title="Documents">
              <b-overlay
                :show="$store.state.matterStore.docsLoading"
                rounded="sm"
                variant="transparent"
                opacity="0.5"
                blur="2px"
              >
                <vue-perfect-scrollbar
                  :ref="refChatLogPS"
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                >
                  <!-- <chat-log
                :chat-data="activeChat"
                :profile-user-avatar="profileUserDataMinimal.avatar"
              /> -->

                  <div v-if="$store.state.matterStore.docsList.length">
                    <!-- badge -->
                    <div
                      v-for="(item, index) in $store.state.matterStore.docsList"
                      v-bind:key="item._id"
                    >
                      <b-card class="mb-1 mx-1">
                        <b-card-text>
                          <b-img
                            src="@/assets/images/icons/file-icons/file.png"
                            width="32px"
                            class="mr-50"
                          />
                          {{
                            item.url.substring(item.url.lastIndexOf('/') + 1)
                          }}
                        </b-card-text>
                        <b-row class="justify-content-between px-1">
                          <span>{{
                            formatDate(item.created_at, {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric'
                            })
                          }}</span>
                          <b-link
                            class="card-link text-primary"
                            :href="item.url"
                            target="_blank"
                          >
                            Open
                          </b-link>
                          <b-link
                            class="card-link text-danger"
                            @click="deleteDocuemntAlert(item._id)"
                          >
                            Delete
                          </b-link>
                        </b-row>
                      </b-card>
                    </div>
                  </div>
                  <h6
                    v-if="
                      !$store.state.matterStore.docsList.length &&
                      !$store.state.matterStore.docsLoading
                    "
                    class="text-center mt-2"
                  >
                    Document Not Available!
                  </h6>
                </vue-perfect-scrollbar></b-overlay
              >
            </b-tab>
          </b-tabs>
        </template>
        <template #footer>
          <div class="px-1 py-1">
            <!-- Notes Input -->
            <b-form
              class="chat-app-form"
              @submit.prevent="saveNote"
              v-if="currentTab === 0"
            >
              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-textarea
                  id="textarea-small"
                  size="sm"
                  v-model="text"
                  placeholder="Enter your note"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    type="submit"
                    :disabled="!text"
                  >
                    Submit
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>

            <!-- Documents Input -->
            <b-form
              class="chat-app-form"
              @submit.prevent="saveDocument"
              v-if="currentTab === 1"
            >
              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-file
                  id="file-large"
                  size="lg"
                  v-model="file"
                  @change="doSomething"
                  type="file"
                  ref="file"
                  :state="Boolean(file)"
                  placeholder="Select Document"
                  accept=".jpg, .png, .jpeg, .pdf, .xls, .docx"
                  class="mr-1"
                />
                <b-button variant="outline-primary" type="submit">
                  Submit
                </b-button>
              </b-input-group>
            </b-form>
          </div>
        </template>
      </b-sidebar>
    </template>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BInputGroupAppend,
  BListGroupItem,
  BListGroup,
  BTab,
  BImg,
  BRow,
  BCardText,
  BLink,
  BCol,
  BCard,
  BTabs,
  BBadge,
  VBToggle,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { formatDate, formatDateToMonthShort } from '@core/utils/filter'
import { ref, nextTick } from '@vue/composition-api'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BListGroupItem,
    BListGroup,
    BButton,
    BBadge,
    BCard,
    BCardText,
    BLink,
    BRow,
    BImg,
    BCol,
    BTab,
    BTabs,
    BOverlay,
    VuePerfectScrollbar,
    BInputGroupAppend
  },
  props: {
    data: {
      default: null
    },
    type: {
      default: 'lg'
    }
  },
  data() {
    return {
      matter: this.data,
      buttonType: this.type,
      text: '',
      file: null,
      refChatLogPS: null,
      currentTab: 0,
      tabs: ['Notes', 'Documents']
    }
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  methods: {
    onTabChange(index) {
      console.log('Tab', index)
      this.currentTab = index
    },
    scrollToBottomInChatLog() {
      const scrollEl = this.refChatLogPS.$el || refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    fetchNotesDocs() {
      this.currentTab = 0
      let matter_id = this.matter._id
      this.$store
        .dispatch('matterStore/getMatterNotes', matter_id)
        .then((response) => {
          console.log('Data', response)
          // Scroll to bottom
          nextTick(() => {
            this.scrollToBottomInChatLog()
          })
        })
      this.$store
        .dispatch('matterStore/getMatterDocuments', matter_id)
        .then((response) => {
          console.log('Data', response)
          // Scroll to bottom
          nextTick(() => {
            this.scrollToBottomInChatLog()
          })
        })
    },
    // Save Note
    saveNote() {
      if (this.text) {
        let data = {
          matter_id: this.matter._id,
          text: this.text
        }
        console.log('Data', data)
        this.$store.dispatch('matterStore/saveNote', {
          ctx: this,
          payload: data
        })
        this.text = ''
      }
    },
    doSomething(e) {
      this.file = e.target.files[0]
    },
    // Save Documen
    saveDocument() {
      console.log('File', this.file)
      if (this.file) {
        const FormData = require('form-data')
        let data = new FormData()
        data.append('matter_id', this.matter._id)
        data.append('document', this.file)
        console.log('Data', data)
        this.$store.dispatch('matterStore/saveDocument', {
          ctx: this,
          payload: data,
          matter_id: this.matter._id
        })
        this.text = ''
      }
    },
    // delete Note alert
    deleteNoteAlert(_id) {
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this note',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('matterStore/deleteNote', {
            ctx: this,
            _id: _id,
            matter_id: this.matter._id
          })
        }
      })
    },
    // delete Document alert
    deleteDocuemntAlert(_id) {
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this document',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('matterStore/deleteDocument', {
            ctx: this,
            _id: _id,
            matter_id: this.matter._id
          })
        }
      })
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150
    }
    return {
      perfectScrollbarSettings,
      formatDate,
      formatDateToMonthShort
    }
  }
}
</script>
