<template>
  <div>
    <b-button
      v-b-modal.modal-create-client
      variant="outline-primary"
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      class="mb-75"
      block
    >
      Client Detail
    </b-button>

    <!-- select 2 demo -->
    <b-modal
      id="modal-create-client"
      title="Client Details"
      no-close-on-backdrop
      centered
      ok-title="submit"
      cancel-variant="outline-secondary"
    >
      <b-card-text>
        <b-overlay
          :show="$store.state.matterStore.clientDetailLoading"
          rounded="sm"
          variant="transparent"
          opacity="0.5"
          blur="2px"
        >
          <validation-observer ref="matterForm" #default="{ invalid }">
            <b-form @submit.prevent="updateClient">
              <!-- Client Name -->
              <div>
                <b-form-group label="Name" label-for="client-name">
                  <b-form-input
                    id="client-name"
                    rules="required"
                    v-model="Name"
                    placeholder="Enter Name"
                  />
                </b-form-group>
              </div>

              <!-- Client Mobile -->
              <div>
                <b-form-group label="Mobile" label-for="client-mobile">
                  <b-form-input
                    id="client-mobile"
                    v-model="Mobile"
                    placeholder="Enter Mobile"
                  />
                </b-form-group>
              </div>

              <!-- Client Email -->
              <div>
                <b-form-group label="Email" label-for="client-email">
                  <b-form-input
                    id="client-email"
                    v-model="Email"
                    placeholder="Enter Email"
                  />
                </b-form-group>
              </div>
            </b-form>
          </validation-observer>
          <template #overlay>
            <div class="text-center">
              <b-spinner variant="secondary"></b-spinner>
              <p id="cancel-label">{{ loadingMsg }}</p>
            </div>
          </template>
        </b-overlay>
      </b-card-text>

      <!-- Footer -->
      <template #modal-footer>
        <!-- By Matter Footer -->
        <div class="w-100">
          <div>
            <b-button
              variant="primary"
              class="float-right"
              type="submit1"
              :disabled="!Name"
              @click="updateClient"
            >
              Submit
            </b-button>
          </div>
          <div>
            <b-button
              variant="outline-dark"
              class="float-right mr-1 ml-1"
              type="cancel"
              @click="$bvModal.hide('modal-create-matter')"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BCardText,
    BFormGroup,
    BTab,
    BTabs,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  props: {
    data: {
      default: null
    }
  },
  data() {
    let name = ''
    let email = ''
    let phone = ''
    if (Object.keys(this.data).includes('public_data')) {
      if (this.data.public_data !== null) {
        if (Object.keys(this.data.public_data).includes('client')) {
          if (this.data.public_data.client !== null) {
            if (Object.keys(this.data.public_data.client).includes('name')) {
              console.log('Name', this.data.public_data.client.name)
              name = this.data.public_data.client.name
            }
            if (Object.keys(this.data.public_data.client).includes('email')) {
              console.log('Email', this.data.public_data.client.email)
              email = this.data.public_data.client.email
            }
            if (Object.keys(this.data.public_data.client).includes('phone')) {
              console.log('Phone', this.data.public_data.client.phone)
              phone = this.data.public_data.client.phone
            }
          }
        }
      }
    }
    return {
      // Matter
      Matter: this.data,
      Name: name,
      Mobile: phone,
      Email: email,
      // Data
      CurrentMatter: null,

      // validation rules
      required
    }
  },
  methods: {
    clearAllFields() {
      // Matter
      ;(this.Name = null), (this.Email = null), (this.Mobile = null)
    },
    updateClient() {
      this.loadingMsg = ''
      event.preventDefault()
      if (this.Name) {
        let dd
        if (Object.keys(this.Matter).includes('public_data')) {
          let oldData = this.Matter.public_data
          oldData.client = {
            name: this.Name,
            email: this.Email,
            phone: this.Mobile
          }
          dd = oldData
        } else {
          dd = {
            client: {
              name: this.Name,
              email: this.Email,
              phone: this.Mobile
            }
          }
        }
        console.log('Data', dd)
        store.dispatch('matterStore/updatePublicData', {
          ctx: this,
          matter_id: this.data._id,
          payload: dd
        })
      }
    },
    refreshMatter() {
      const id = router.currentRoute.params.id
      console.log('Matter Id --> ', id)
      this.$store.dispatch('matterStore/refreshMatter', { ctx: this, id: id })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
