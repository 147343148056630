<template>
  <section class="pb-1">
    <h5 class="text-nowrap font-weight-bolder" v-if="data.unique_id">
      {{ getUniqueId(data) }}
      <b-badge
        variant="light-primary"
        class="mx-1"
        v-if="data.case_status.toLowerCase() === 'pending'"
      >
        <span>{{ data.case_status }}</span>
      </b-badge>
      <b-badge variant="light-danger" class="mx-1" v-else>
        <span>{{ data.case_status }}</span>
      </b-badge>
    </h5>
    <div v-if="Object.keys(data).includes('ctitle')">
      <p class="text-secondary font-italic">
        {{ data.ctitle }}
        {{
          Object.keys(data).includes('other_court_name') &&
          data.other_court_name !== null
            ? '- ' + data.other_court_name
            : ''
        }}
      </p>
    </div>
    <h4 class="text-primary" v-if="!titleEditToggle">
      {{ data.title }}
      <b-button
        squared
        variant="outline-primary"
        size="sm"
        :pressed.sync="titleEditToggle"
        >Edit</b-button
      >
    </h4>
    <b-col v-else class="p-0">
      <b-form-group label="Case Title" label-for="case-title">
        <b-form-textarea
          id="case-title"
          v-model="title"
          type="text"
          class="m-0"
          placeholder="Case Title"
          rows="2"
        />
      </b-form-group>
      <b-row class="m-0">
        <b-button
          squared
          variant="primary"
          size="sm"
          :disabled="!title"
          @click="updateTitle"
          >Update</b-button
        >
        <b-button
          squared
          variant="outline-secondary"
          size="sm"
          :pressed.sync="titleEditToggle"
          >Cancel</b-button
        >
      </b-row>
    </b-col>
    <b-row class="justify-content-between">
      <b-col>
        <h4 class="text-secondary" v-if="!fileEditToggle">
          <span>File No. : {{ data.file_no }} </span>
          <b-button
            squared
            variant="outline-primary"
            size="sm"
            :pressed.sync="fileEditToggle"
            >Edit</b-button
          >
        </h4>
        <b-col v-else class="p-0">
          <b-form-group label="Case File No." label-for="case-file-no">
            <b-form-input
              id="case-file-no"
              v-model="file_no"
              type="text"
              class="m-0"
              placeholder="Case File No."
              rows="1"
            />
          </b-form-group>
          <b-row class="m-0">
            <b-button
              squared
              variant="primary"
              size="sm"
              :disabled="!file_no"
              @click="updateFileNo"
              >Update</b-button
            >
            <b-button
              squared
              variant="outline-secondary"
              size="sm"
              :pressed.sync="fileEditToggle"
              >Cancel</b-button
            >
          </b-row>
        </b-col>
      </b-col>
      <div>
        <b-row>
          Not Mine:
          <b-form-checkbox
            :checked="not_mine"
            class="custom-control-danger px-1"
            name="check-button"
            switch
            @change="updateNotMine"
        /></b-row>
      </div>
    </b-row>
  </section>
</template>

<script>
import {
  BBadge,
  BRow,
  BButton,
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormInput
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormInput
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      titleEditToggle: false,
      fileEditToggle: false,
      title: this.matter.title,
      file_no: this.matter.file_no,
      data: this.matter,
      not_mine:
        Object.keys(this.matter).includes('not_mine') &&
        this.matter['not_mine'] != null
          ? this.matter['not_mine']
          : false
    }
  },
  beforeMount() {},
  // Vinay Kumar Singh Vs ANSAL PROPERTIES & INFRASTRUCTURE LIMITED
  methods: {
    updateNotMine(checked) {
      console.log('Checked', checked)
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            not_mine: checked
          }
        })
        .then((response) => {
          console.log(response)
          this.not_mine = checked
        })
    },
    updateTitle() {
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            title: this.title
          }
        })
        .then((response) => {
          console.log(response)
          if (response.success) {
            this.data.title = response.data.title
            this.title = response.data.title
            this.titleEditToggle = false
          }
        })
    },
    updateFileNo() {
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            file_no: this.file_no
          }
        })
        .then((response) => {
          console.log(response)
          if (response.success) {
            this.data.file_no = response.data.file_no
            this.file_no = response.data.file_no
            this.fileEditToggle = false
          }
        })
    }
  }
}
</script>
