<template>
  <div>
    <!-- Basic Details -->
    <basic-details
      :matter="$store.state.matterStore.currentmatter"
    ></basic-details>
    <!-- Case Status -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'case_details'
        )
      "
    >
      <case-status
        :matter="$store.state.matterStore.currentmatter"
        :detail="$store.state.matterStore.currentmatter.case_details"
      ></case-status>
    </div>
    <!-- Category -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes('category')
      "
    >
      <category-details
        :matter="$store.state.matterStore.currentmatter"
      ></category-details>
    </div>
    <!-- Linked Matters -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'linked_matters'
        )
      "
    >
      <linked-matters
        v-if="$store.state.matterStore.currentmatter.linked_matters"
        :matter="$store.state.matterStore.currentmatter"
      ></linked-matters>
    </div>
    <!-- Peitioner & Respondent -->
    <div
    >
      <petitioner-respondents
        :matter="$store.state.matterStore.currentmatter"
      ></petitioner-respondents>
    </div>

    <!-- Copy Petition Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'copy_petition_details'
        )
      "
    >
      <basic-table
        :data="$store.state.matterStore.currentmatter.copy_petition_details"
        :title="'Copy Petition Details'"
      ></basic-table>
    </div>

    <!-- Lower Court Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'lower_court_detail'
        )
      "
    >
      <basic-table
        :data="$store.state.matterStore.currentmatter.lower_court_detail"
        :title="'Lower Court Details'"
      ></basic-table>
    </div>

    <!-- FIR Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'fir_details'
        )
      "
    >
      <basic-table
        :data="$store.state.matterStore.currentmatter.fir_details"
        :title="'FIR Details'"
      ></basic-table>
    </div>

    <!-- Impung Order Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'impung_order_detail'
        )
      "
    >
      <basic-table
        :data="$store.state.matterStore.currentmatter.impung_order_detail"
        :title="'Impung Order Details'"
      ></basic-table>
    </div>

    <!-- Complaint Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'complaint_details'
        )
      "
    >
      <basic-table
        :data="$store.state.matterStore.currentmatter.complaint_details"
        :title="'Complaint Details'"
      ></basic-table>
    </div>

    <!-- Lac Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'lac_detail'
        )
      "
    >
      <basic-table
        :data="$store.state.matterStore.currentmatter.lac_detail"
        :title="'Lac Details'"
      ></basic-table>
    </div>

    <!-- Listening History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'listing_history'
        )
      "
    >
      <listing-details
        :matter="$store.state.matterStore.currentmatter"
      ></listing-details>
    </div>
    <!-- Crime Details & Lower Court -->
    <b-row>
      <!-- Crime Details -->
      <b-col
        class="col-12 col-md-6 mb-1"
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'crime_dic'
          )
        "
      >
        <crime-details
          :matter="$store.state.matterStore.currentmatter"
        ></crime-details>
      </b-col>
      <!-- Lower Court -->
      <b-col
        class="col-12 col-md-6 mb-1"
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'lower_court'
          )
        "
      >
        <lower-court
          :matter="$store.state.matterStore.currentmatter"
        ></lower-court>
      </b-col>
    </b-row>
    <!-- IA Details -->
    <div
      v-if="Object.keys($store.state.matterStore.currentmatter).includes('ia')"
    >
      <DynamicListTable
        :data="$store.state.matterStore.currentmatter.ia"
        title="IA Details"
      ></DynamicListTable>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// Section Imports
import BasicDetails from './components/basic-details/BasicDetails.vue'
import CaseStatus from './components/status/CaseStatus.vue'
import ListingDetails from './components/listing-history/ListingDetails.vue'
import PetitionerRespondents from './components/petitioner-respondent/PetitionerRespondents.vue'
import DynamicListTable from './components/DynamicListTable.vue'
import CategoryDetails from './components/CategoryDetails.vue'
import LinkedMatters from './components/LinkedMatters.vue'
import LowerCourt from './components/LowerCourt.vue'
import CrimeDetails from './components/CrimeDetails.vue'
import BasicTable from './components/tables/BasicTable.vue'

export default {
  components: {
    BRow,
    BCol,
    // Basic Details
    BasicDetails,
    CaseStatus,
    ListingDetails,
    PetitionerRespondents,
    DynamicListTable,
    CategoryDetails,
    LinkedMatters,
    LowerCourt,
    CrimeDetails,
    BasicTable
  }
}
</script>
