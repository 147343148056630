var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('basic-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'case_details'
      )
    )?_c('div',[_c('district-case-status',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter,"detail":_vm.$store.state.matterStore.currentmatter.case_details}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes('acts')
    )?_c('div',[_c('act-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'linked_matters'
      )
    )?_c('div',[(_vm.$store.state.matterStore.currentmatter.linked_matters)?_c('linked-matters',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}):_vm._e()],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'crime_dic'
      )
    )?_c('div',[_c('f-i-r-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter,"detail":_vm.$store.state.matterStore.currentmatter.case_details}})],1):_vm._e(),_c('div',[_c('petitioner-respondents',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'listing_history'
      )
    )?_c('div',[_c('listing-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'transfer_history'
      )
    )?_c('div',[_c('transfer-history',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }