<template>
  <section class="matter-preview-wrapper">
    <b-row
      class="matter-preview"
      v-if="
        $store.state.matterStore.currentmatter &&
        !$store.state.matterStore.matterPreviewLoading
      "
    >
      <!-- Col: Left (Matter Container) -->
      <b-col cols="12" lg="8">
        <b-card>
          <!-- Action Buttons -->
          <b-row class="justify-content-end px-1 d-md-none">
            <b-row>
              <matter-order-judgment
                class="mb-1"
                :data="$store.state.matterStore.currentmatter"
                :type="'sm'"
              />
              <matter-notes-docs
                class="ml-75 mb-1"
                :data="$store.state.matterStore.currentmatter"
                :type="'sm'"
              />
              <b-overlay
                :show="$store.state.matterStore.matterRefreshLoading"
                rounded="sm"
                variant="transparent"
                opacity="0.5"
                blur="2px"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  :disabled="$store.state.matterStore.matterRefreshLoading"
                  class="btn-sm mb-1 ml-75"
                  @click="refreshMatter"
                >
                  Refresh
                </b-button>
              </b-overlay>
            </b-row>
          </b-row>
          <b-row class="justify-content-end px-1 d-md-none">
            <p v-if="$store.state.matterStore.matterRefreshLoading">
              Connecting to court...
            </p>
          </b-row>
          <!-- Basic Details -->
          <district-details
            v-if="
              $store.state.matterStore.currentmatter.hc_name.includes('ED-')
            "
          ></district-details>
          <h-c-details
            v-if="
              $store.state.matterStore.currentmatter.hc_name.includes('hc-')
            "
          ></h-c-details>
          <s-c-details
            v-if="
              $store.state.matterStore.currentmatter.hc_name.includes('sci')
            "
          ></s-c-details>
          <tribunal-details
            v-else-if="
              $store.state.matterStore.currentmatter.hc_name.includes(
                'tribunal'
              )
            "
          ></tribunal-details>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" lg="4" class="matter-actions">
        <b-card>
          <p class="p-0 m-0" v-if="Object.keys($store.state.matterStore.currentmatter).includes('created_at') && $store.state.matterStore.currentmatter.created_at != null && $store.state.matterStore.currentmatter.created_at != ''"><small>Created At:</small> {{ getDate({date: $store.state.matterStore.currentmatter.created_at}) }}</p>
          <p class="" v-if="Object.keys($store.state.matterStore.currentmatter).includes('updated_at') && $store.state.matterStore.currentmatter.updated_at != null && $store.state.matterStore.currentmatter.updated_at != ''"><small>Updated At:</small> {{ getDate({date: $store.state.matterStore.currentmatter.updated_at}) }}</p>
          <client-button
            :data="$store.state.matterStore.currentmatter"
          ></client-button>
          <!-- Button: Judgment Matter -->
          <matter-order-judgment
            :data="$store.state.matterStore.currentmatter"
            :type="'lg'"
          />

          <!-- Button: Docs -->
          <matter-notes-docs :data="$store.state.matterStore.currentmatter" />

          <matter-invoice
            class="mb-1"
            :data="$store.state.matterStore.currentmatter"
          />

          <!-- Button: Delete -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            class="mb-75"
            block
            @click="deleteAlert($store.state.matterStore.currentmatter._id)"
          >
            Delete Matter
          </b-button>
          <!-- Button: Refresh -->
          <b-overlay
            :show="$store.state.matterStore.matterRefreshLoading"
            rounded="sm"
            variant="transparent"
            opacity="0.5"
            blur="2px"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              v-b-toggle.matter-edit
              variant="primary"
              class="mb-75"
              :disabled="$store.state.matterStore.matterRefreshLoading"
              block
              @click="refreshMatter"
            >
              Refresh Matter
            </b-button>
          </b-overlay>
          <p class="p-0 m-0" v-if="$store.state.matterStore.matterRefreshLoading">
            Connecting to court...
          </p>
        </b-card>
      </b-col>
    </b-row>

    <b-card
      v-if="
        !$store.state.matterStore.currentmatter &&
        $store.state.matterStore.matterPreviewLoading
      "
      class="text-center mt-2"
    >
      <div class="d-flex justify-content-center my-2">
        <b-spinner label="Loading..." />
      </div>
    </b-card>

    <b-card
      v-if="
        !$store.state.matterStore.currentmatter &&
        !$store.state.matterStore.matterPreviewLoading
      "
      class="text-center mt-2"
    >
      Please Try Again!
    </b-card>
  </section>
</template>

<script>
// import { ref, onUnmounted } from '@vue/composition-api'
// import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BButtonGroup,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BSpinner,
  BLink,
  VBToggle,
  BOverlay
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import MatterOrderJudgment from './judgment/MatterOrderJudgment.vue'
import MatterInvoice from './invoice/MatterInvoice.vue'
import MatterNotesDocs from './notesDocs/MatterNotesDocs.vue'
import ClientButton from './components/ClientButton.vue'
import moment from 'moment'
// Types
import HCDetails from './HCDetails.vue'
import DistrictDetails from './DistrictDetails.vue'
import TribunalDetails from './TribunalDetails.vue'
import SCDetails from './SCDetails.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BSpinner,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BButtonGroup,
    BOverlay,

    Logo,
    MatterOrderJudgment,
    MatterInvoice,
    MatterNotesDocs,
    ClientButton,
    // Types
    HCDetails,
    DistrictDetails,
    TribunalDetails,
    SCDetails
  },
  methods: {
    getDate({ date }) {
      var dd = moment(date).format('Do, MMMM YYYY')
      return dd
    },
    // delete alert
    deleteAlert(_id) {
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this matter',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('matterStore/deleteMatter', {
              ctx: this,
              _id: _id
            })
            .then((result) => {
              // this.refetchData()
            })
        }
      })
    },
    refreshMatter() {
      const id = router.currentRoute.params.id
      console.log('Matter Id --> ', id)
      this.$store.dispatch('matterStore/refreshMatter', {
        ctx: this,
        id: id,
        unique_id: this.$store.state.matterStore.currentmatter.hc_name
      })
    }
  },
  watch: {
    '$route.params.id'(id) {
      console.log('Matter Id --> ', id)
      this.$store.dispatch('matterStore/fetchMatter', { id: id })
    }
  },
  beforeCreate() {
    const id = router.currentRoute.params.id
    console.log('Matter Id --> ', id)
    this.$store.dispatch('matterStore/fetchMatter', { id: id })
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Matter Specific Styles
  .matter-preview-wrapper {
    .row.matter-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .matter-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 80% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .matter-actions {
      display: none;
    }
  }
}
</style>
